import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { socket } from "../../../socket";
import { useDispatch, useSelector } from "react-redux";
import useChat from "./esgGptChat/useChat";
import {
  fetchAnswerFromMongo,
  setDisclaimer,
  updateAnswerForConvo,
  updateChartForConvo,
  updateThinkingForConvo,
} from "../../../redux/actions/gpt/esgGptAction";
import { useStyles } from "./esgGptCompanyProfile/EsgGptCompanyRight";
import DisclaimerDialog from "./esgGptChat/genericChatComps/ChatDisclaimer/DisclaimerDialog";
import PlanExpired from "./PlanExpired";
import { Box, Typography } from "@material-ui/core";
import UpdatedDisclaimer from "./esgGptCompanyProfile/EsgCompanyRightComps/UpdatedDisclaimer";
import EsgGptChatMessageLayout from "./esgGptChat/EsgGptChatMessageLayout";

// redux chat actions
import {
  resetChatTimer,
  stopChatTimer,
} from "../../../redux/actions/chatTimers/ChatTimersActions";
import { chatRefreshTimer } from "../../../config";

export function ChatPlayGround({ companyName }) {
  const history = useHistory();

  const { location } = history;
  const isCompanyBased = location.pathname === "/esg_gpt/chat" ? false : true;

  const { chats, isDisclaimer, selectedChat, newOrganization } = useSelector(
    (state) => state.esgGptReducer
  );

  const user_id = localStorage.getItem("user_id");

  const chatAutoSuggestion = [
    `Net Zero Operations for ${companyName}`,
    `Diversity and Inclusion for ${companyName}`,
    `ESG achievements for ${companyName}`,
    `Summarize on employee engagement and well being for ${companyName}`,
  ];

  const chatSuggestion = [
    `Come up with a concept for ESG`,
    `Recommend a framework`,
    `Compare ESG Principles`,
  ];

  const chatSuggestionCOndition = !isCompanyBased
    ? chatSuggestion
    : chatAutoSuggestion;

  const { handleChats } = useChat();
  const [chatInitiate, setChatInitiate] = useState(false);

  const chat_tokens = JSON.parse(localStorage.getItem("chat_tokens"));

  // plan expired states
  const [planExpired, setPlanExpired] = useState(false);

  const togglePlanExpired = () => {
    setPlanExpired(!planExpired);
  };

  const handleChatSuggestion = (question) => {
    if (!chat_tokens || chat_tokens <= 0) {
      setPlanExpired(true);
    } else {
      setChatInitiate(true);
      handleChats(question, companyName);
    }
  };
  const dispatch = useDispatch();

  // main socket handlers !Important (Make sure this useEffect always runs only once)

  // -------------------- handlers --------------------
  const handleSocketEvents = (socket) => {
    if (!socket || !user_id) {
      console.log(`Unable to connect to Socket server`);
      return;
    } else {
      // Handle socket connection
      socket.on("connect", () => {
        console.log("recovered?", socket.recovered);
        console.log(`Connected to Socket server`);
      });

      socket.on("disconnect", (disconnectData) => {
        console.log(`Disconnected from Socket`, disconnectData);
      });

      // Handle "thinking" event
      socket.on("thinking", (thinkingData) => {
        // check if this is not company chat and current convo is correct for receiving
        const room_id = thinkingData?.chat_group_id;

        console.log("Thinking now: ", thinkingData);

        if (
          (!isCompanyBased && selectedChat?._id === room_id) ||
          (isCompanyBased && newOrganization?._id === room_id)
        ) {
          dispatch(
            updateThinkingForConvo(
              thinkingData?.question_id,
              thinkingData?.data
            )
          );
          const question_id = thinkingData?.question_id;
          // Reset the timer for this question ID
          dispatch(
            resetChatTimer(question_id, chatRefreshTimer, () =>
              fetchAnswerFromMongo(question_id, isCompanyBased)
            )
          );
        }
      });

      // Handle "update_chart" event
      socket.on("update_chart", (chartData) => {
        // check if this is not company chat and current convo is correct for receiving
        const room_id = chartData?.chat_group_id;

        console.log("Response from Socket Server: ", chartData);

        if (
          (!isCompanyBased && selectedChat?._id === room_id) ||
          (isCompanyBased && newOrganization?._id === room_id)
        ) {
          dispatch(
            updateChartForConvo(chartData?.question_id, chartData?.chart_props)
          );

          const question_id = chartData?.question_id;

          // Stop the timer for this question ID
          dispatch(stopChatTimer(question_id));
        }
      });

      // Handle "final_result" event
      socket.on("final_result", (finalData) => {
        // check if this is not company chat and current convo is correct for receiving
        const room_id = finalData?.chat_group_id;

        console.log("Response from Socket Server: ", finalData);

        if (
          (!isCompanyBased && selectedChat?._id === room_id) ||
          (isCompanyBased && newOrganization?._id === room_id)
        ) {
          dispatch(updateAnswerForConvo(finalData?.question_id, finalData));

          const question_id = finalData?.question_id;

          // Stop the timer for this question ID
          dispatch(stopChatTimer(question_id));
        }
      });

      // Handle "error" event
      socket.on("error", (error) => {
        console.log("Socket error: ", error);
        if (error?.message === "Error receiving message") {
          console.log("Unable to register your question");
        }
      });
    }
  };
  // ----------------------------------------------------

  // ----------- useEffects -----------

  // useEffect to handle socket events
  useEffect(() => {
    handleSocketEvents(socket);

    return () => {
      // shutting off previous connections
      if (socket) {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("thinking");
        socket.off("final_result");
        socket.off("error");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrganization?._id, selectedChat?._id]);
  // ----------------------------------

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isDisclaimer")) && chats.length > 0) {
      dispatch(setDisclaimer(true));
    }
  }, [isDisclaimer, chats]);

  const classes = useStyles();

  return (
    <>
      {<DisclaimerDialog isDisclaimer={isDisclaimer} />}
      {planExpired && (
        <PlanExpired isOpen={planExpired} handleClose={togglePlanExpired} />
      )}
      {chats.length === 0 && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            fontFamily: "poppins",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            {
              // if not in generic chat
              location.pathname !== "/esg_gpt/chat" &&
                chatSuggestionCOndition.map((suggestion, index) => (
                  <div
                    style={{
                      width: "32%",
                      textAlign: "center",
                      cursor: "pointer",
                      // border: "solid",
                    }}
                    onClick={() => handleChatSuggestion(suggestion)}
                    className={classes.suggestion}
                    key={index}
                  >
                    <Typography
                      className={classes.typoColorSecondary}
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        // color: "#242424",
                        padding: "15px",
                        lineHeight: "20px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {suggestion}
                    </Typography>
                  </div>
                ))
            }
          </div>
          {/* Updated Disclaimer */}
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <UpdatedDisclaimer />
          </Box>
        </Box>
      )}
      {(chatInitiate || chats.length > 0) && (
        <div style={{ height: isCompanyBased ? "75vh" : "80vh" }}>
          <EsgGptChatMessageLayout
            companyName={companyName}
            setPlanExpired={setPlanExpired}
          />
        </div>
      )}
    </>
  );
}
